<template>
  <div class="mt-3">
    <p class="black px-3">
      Profile completion level: <strong>{{ currentLevel }}</strong>
    </p>
    <rising-star-progress-bar
      class="mobile_spacing"
      :currentValue="user.currentProgress > 0 ? user.currentProgress * 2 : 0"
    />
    <p class="black px-3" v-if="!user.careerMatching">
      It will take less than {{ stepTime }} minutes to reach the
      <strong>{{ nextLevel }}</strong>
      {{ currentLevel === 'Talent' ? null : level }}
    </p>
    <ul class="list-margin purple" v-if="user.currentProgress === 0">
      <li>
        <i class="fa fa-check" aria-hidden="true"></i> Unlock Excelerate Thesis
        Collaboration Matching
      </li>
      <li>
        <i class="fa fa-check" aria-hidden="true"></i> Match with companies in
        your industry
      </li>
      <li>
        <i class="fa fa-check" aria-hidden="true"></i> Upload thesis topic /
        project proposal
      </li>
    </ul>
    <ul
      class="list-margin purple"
      v-if="user.currentProgress > 0 && user.currentProgress <= 50"
    >
      <li>
        <i class="fa fa-check" aria-hidden="true"></i> Unlock Excelerate Career
        Matching
      </li>
      <li>
        <i class="fa fa-check" aria-hidden="true"></i> Triple thesis
        collaboration chances
      </li>
      <li>
        <i class="fa fa-check" aria-hidden="true"></i> Boost profile to stand
        out
      </li>
    </ul>
    <p
      class="black px-3"
      v-if="user.currentProgress === 100 && !user.careerMatching"
    >
      Once you have enabled Excelerate Career Matching we will start to look for
      companies who match your profile.
    </p>
    <p
      class="black px-3"
      v-if="user.currentProgress === 100 && user.careerMatching"
    >
      Awesome, you have completed your profile. We are now actively looking for
      companies who match your profile.
    </p>
    <p
      class="black px-3"
      v-if="user.currentProgress === 100 && user.careerMatching"
    >
      Would you like to have a free call with a Excelerate consultant to talk
      about your thesis or opportunities with Excelerate?
    </p>
    <div class="text-center">
      <router-link
        v-if="user.currentProgress === 0 || user.currentProgress < 50"
        to="/user/student-rising-star"
        class="btn button-pink"
        >Become a Rising Star</router-link
      >
      <router-link
        v-if="user.currentProgress >= 50 && user.currentProgress < 100"
        to="/user/student-rising-star"
        class="btn button-pink"
        >Become a Talent</router-link
      >
      <router-link
        v-if="user.currentProgress === 100 && !user.careerMatching"
        to="/user/student-talent/unlock"
        class="btn button-pink"
        >Activate Excelerate Career Matching</router-link
      >
      <button
        v-if="user.currentProgress === 100 && user.careerMatching"
        onclick="Calendly.initPopupWidget({url: 'https:\/\/calendly.com/excelerate-onboarding/thesis-supervision'});return false;"
        class="btn button-pink"
      >
        Book a call
      </button>
    </div>
  </div>
</template>

<script>
import { mapState } from 'vuex';
import RisingStarProgressBar from '../RisingStarProgressBar.vue';
export default {
  components: { RisingStarProgressBar },
  computed: {
    ...mapState(['user']),
    nextLevel() {
      if (this.user.currentProgress >= 0 && this.user.currentProgress < 50) {
        return 'Rising Star';
      } else if (
        this.user.currentProgress >= 50 &&
        this.user.currentProgress < 100
      ) {
        return 'Talent';
      } else if (
        this.user.currentProgress === 100 &&
        !this.user.careerMatching
      ) {
        return 'Excelerate Career Matching.';
      }
      return null;
    },
    currentLevel() {
      if (this.user.currentProgress >= 0 && this.user.currentProgress < 50) {
        return 'Explorer';
      } else if (
        this.user.currentProgress >= 50 &&
        this.user.currentProgress < 100
      ) {
        return 'Rising Star';
      } else if (this.user.currentProgress === 100) {
        return 'Talent';
      }
      return null;
    },
    stepTime() {
      return this.user.currentProgress < 100 ? 5 : 2;
    },
  },
};
</script>

<style scoped>
ul li {
  list-style: none;
}

@media (max-width: 599px) {
  .mobile_spacing {
    margin-top: 50px;
    margin-bottom: 50px;
  }
}
</style>
