<template>
  <div>
    <MasterHeader title="Dashboard" />
    <Loader :times="2" v-if="!user.role"></Loader>
    <template v-else>
      <sign-up-modal v-if="hasNewSignupFlow"/>
      <div class="dashboard" v-if="user.role != 'Student'">
        <div class="container container-wide">
          <div class="row">
            <div class="col-12">
              <div class="widget">
                <div class="widget-splash splash-line"></div>
                <div class="widget-header">
                  <h4>Welcome</h4>
                  <div class="widget-navigation">
                    <router-link :to="'/user/profile-edit'" class="widget-nav"
                      ><i class="fas fa-user-edit"></i>Edit profile</router-link
                    >
                  </div>
                </div>
                <div class="widget-body profile">
                  <div class="row" v-if="user.role === 'Student'">
                    <div class="col-12 col-md-8">
                      <h4>
                        {{ user.firstName }} {{ user.lastName }}
                        <!-- <span class="badge">student</span> -->
                      </h4>
                      <div class="">
                        {{ user.institution.name }} | {{ user.faculty }} |
                        {{ user.degree }} | {{ user.area }} |
                        {{ user.secondaryArea }} | Graduating
                        {{ user.graduatingSemester }}
                      </div>
                    </div>
                    <div class="col-12 col-md-4 text-right">
                      <img
                        :src="
                          '/assets/institutions/' + user.institution.id + '.png'
                        "
                        class="img-fluid"
                        style="max-width: 300px"
                        :title="user.institution.name"
                      />
                    </div>
                  </div>

                  <div class="row mb-3" v-if="user.role === 'Professional'">
                    <div class="col-12 col-md-8">
                      <h4>{{ user.firstName }} {{ user.lastName }}</h4>
                      <!-- <div class="">
                                         {{ user.favorites.length}} bookmarks
                                    </div> -->
                    </div>
                  </div>

                  <div class="row">
                    <div class="col-12 col-md-3">
                      Email
                      <div class="headline">
                        {{ user.email ? user.email : null }}
                      </div>
                    </div>
                    <div
                      class="col-12 col-md-3"
                      v-if="user.role === 'Professional'"
                    >
                      Phone
                      <div class="headline">
                        {{ user.phoneNumber ? user.phoneNumber : null }}
                      </div>
                    </div>
                    <div class="col-12 col-md-6">
                      Interests
                      <div class="headline">
                        {{ user.interests ? user.interests.name : null }}
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>

            <!-- <div class="col-12" style="background-color: green;" v-if="this.$route.params.widget === 'welcome'">
                    welcome NEW user. -> start guide
                </div> -->

            <!-- PROFESSIONAL - YOU HAVE BEEN INVITED TO A COMPANY -->
            <div
              class="col-12 col-md-6"
              v-if="
                user.role === 'Professional' &&
                companyInvites &&
                companyInvites.length > 0 &&
                user.company.length === 0
              "
            >
              <div class="widget">
                <div
                  class="widget-splash"
                  style="
                    background-image: url('/assets/company-invitation.jpg');
                  "
                ></div>
                <div class="widget-header">
                  <h4>Invite</h4>
                  <!-- <div class="widget-navigation">
                                <router-link :to="'/company/new'" class="widget-nav"><i class="fas fa-plus"></i>Register</router-link>
                            </div> -->
                </div>
                <div class="widget-body">
                  <div
                    v-for="(invite, index) in companyInvites"
                    :key="index"
                    class="erow row d-flex"
                  >
                    <div class="col-1 align-self-center">
                      <i class="fas fa-envelope"></i>
                    </div>
                    <div class="col-5 align-self-center">
                      <div class="">
                        {{ invite.invites.sender }} invites you to join
                      </div>
                      <div class="headline">
                        {{ invite.invites.companyName }}
                      </div>
                    </div>
                    <div class="col-6 align-self-center text-right">
                      <button
                        type="button"
                        class="btn btn-link color-third mr-2"
                        @click="declineCompanyInvitation(invite)"
                      >
                        Decline
                      </button>
                      <button
                        type="button"
                        class="btn btn-primary"
                        @click="acceptCompanyInvitation(invite)"
                      >
                        Accept
                      </button>

                      <!-- <i class="fas fa-ban cursor-pointer mr-3" title="Decline invitation"></i> -->
                      <!-- <i class="fas fa-check color-first cursor-pointer" @click="acceptCompanyInvitation(invite)" title="Accept invitation"></i> -->
                    </div>
                  </div>
                </div>
              </div>
            </div>

            <!-- PROFESSIONAL - REGISTER COMPANY -->
            <div
              class="col-12 col-md-6"
              v-if="
                (user.role === 'Professional' &&
                  user.company &&
                  user.company.length === 0 &&
                  companyInvites &&
                  companyInvites.length === 0) ||
                (user.role === 'Professional' && user.company === null)
              "
            >
              <div class="widget">
                <div class="widget-header">
                  <h4>Guide</h4>
                  <div class="widget-navigation">
                    <!-- <router-link :to="'/company/new'" class="widget-nav"><i class="fas fa-plus"></i>Register</router-link> -->
                  </div>
                </div>
                <div class="widget-body">
                  <div class="mb-3">
                    <h2>Next step</h2>
                    <p>
                      To use the Excelerate platform you have to register your
                      company.<br />
                      If your company is already using Excelerate, they can send
                      you an invite to join the company group.
                    </p>
                    <div class="text-center">
                      <router-link
                        :to="'/company/new'"
                        class="btn btn-primary btn-lg btn-block mt-2"
                        >Register your company</router-link
                      >
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <!-- STUDENT COLLABORATION WIDGET -->
            <div v-if="user.role === 'Student'" class="col-12 col-md-6">
              <div class="widget">
                <div class="widget-header sticky">
                  <h4>Published collaborations</h4>
                  <div class="widget-navigation">
                    <router-link :to="'/collaboration/my'" class="widget-nav"
                      ><i class="fas fa-hands-helping"></i>My
                      collaboration</router-link
                    >
                  </div>
                </div>
                <div class="widget-body">
                  <template
                    v-if="collaborationsByUser && collaborationsByUser.length"
                  >
                    <router-link
                      :to="'/collaborations/' + collab.id"
                      class="card"
                      v-for="(collab, index) in collaborationsByUser"
                      :key="index"
                    >
                      <div class="card-image">
                        <img
                          :src="
                            '/assets/institutions/' +
                            collab.collaboration.institution.id +
                            '.png'
                          "
                          class="card-img-top"
                          :title="
                            collab.collaboration.institution.name
                              ? collab.collaboration.institution.name
                              : null
                          "
                        />
                      </div>
                      <div class="card-body">
                        <div class="card-area">
                          <small>{{
                            cutText(collab.collaboration.area, 60)
                          }}</small>
                        </div>
                        <hr />
                        <h2 class="card-title">
                          {{ cutText(collab.collaboration.title, 100) }}
                        </h2>
                        <h6 class="card-subtitle">
                          {{ collab.collaboration.subtitle }}
                        </h6>
                        <p class="card-text">
                          {{ cutText(collab.collaboration.content, 300) }}
                        </p>
                        <div class="card-bottom">
                          <span>{{ collab.collaboration.degree }}</span
                          ><span class="divider">|</span>
                          <span>{{ collab.collaboration.targetSemester }}</span
                          ><span class="divider">|</span>
                          {{
                            collab.collaboration.members &&
                            collab.collaboration.members.length
                          }}
                          members
                          <!-- <span class="badge" v-if="collab.collaboration.members.includes(user.userId)">yours</span> -->
                          <span class="btn btn-link float-right">View</span>
                        </div>
                      </div>
                    </router-link>
                  </template>

                  <div class="" v-else>
                    <div class="mb-3">
                      <h2>Next step</h2>
                      <p>
                        Fill out the thesis title and description, save the
                        draft and invite your thesis group to join you here.<br />
                        <br />
                        Click ‘start collaborating’ to get going
                      </p>
                      <div class="text-center">
                        <router-link
                          :to="'/collaboration/new'"
                          class="btn btn-primary btn-block btn-lg"
                          >Start collaborating</router-link
                        >
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>

            <!-- PROFESSIONAL - OFFERS -->
            <!-- <div v-if="user.role === 'Professional' && user.company.length > 0 && companyOffers.length > 0" class="col-12 temp-widget">
                    <div class="widget">
                        <div class="widget-header">
                            <h4>Latest negotiations</h4>
                        </div>
                        <div class="widget-body">
                            <router-link :to="'/collaborations/' + offer.offer.collaborationId" v-for="(offer, index) in companyOffers" :key="index" class="erow row link d-flex">
                                <div class="col-1 align-self-center">
                                    <i class="fas fa-hands-helping" ></i>
                                </div>
                                <div class="col-5 align-self-center">
                                    Title
                                    <div class="headline">
                                        <span class="highlight">{{offer.offer.collaborationTitle}}</span>
                                    </div>
                                </div>
                                <div class="col-3 align-self-center">
                                    Status
                                    <div class="headline">
                                        Waiting for your accept
                                    </div>
                                </div>
                                <div class="col-3 align-self-center text-right">
                                    <span class="btn btn-link color-third">View</span>
                                </div>
                            </router-link>
                            <div class="text-center">
                                <router-link :to="'/companies/' + user.company" class="btn btn-primary">
                                    View all negotiations 
                                </router-link>
                            </div>

                        </div>
                    </div>
                </div> -->

            <div class="col-12 col-md-6">
              <!-- STUDENT COLLABORATION INVITES -->
              <div
                v-if="
                  user.role === 'Student' &&
                  collaborationInvites &&
                  collaborationInvites.length > 0
                "
              >
                <div class="widget">
                  <div class="widget-header">
                    <h4>Invites</h4>
                    <div class="widget-navigation">
                      <router-link :to="'/collaboration/my'" class="widget-nav"
                        ><i class="fas fa-link"></i>My
                        collaborations</router-link
                      >
                    </div>
                  </div>
                  <div class="widget-body">
                    <router-link
                      :to="
                        '/collaborations/' +
                        invite.invites.collaborationId +
                        '#members'
                      "
                      v-for="(invite, index) in collaborationInvites"
                      :key="index"
                      class="erow row link d-flex"
                    >
                      <div class="col-1 align-self-center">
                        <i class="fas fa-envelope"></i>
                      </div>
                      <div class="col-4 align-self-center">
                        From
                        <div class="headline">{{ invite.invites.sender }}</div>
                      </div>
                      <div class="col-5 align-self-center">
                        Collaboration
                        <div class="headline">
                          {{ cutText(invite.invites.collaborationTitle, 40) }}
                        </div>
                      </div>
                      <div class="col-2 align-self-center">
                        <span class="btn btn-link">View</span>
                      </div>
                    </router-link>
                  </div>
                </div>
              </div>

              <div class="widget">
                <div class="widget-header">
                  <h4>Lastest topics</h4>
                  <div class="widget-navigation">
                    <!-- <router-link :to="'/collaborations/'" class="widget-nav color-third"><i class="fas fa-search"></i>Find collaborations</router-link> -->
                  </div>
                </div>
                <div class="widget-body">
                  <router-link
                    :to="'/collaborations/' + collab.id"
                    v-for="(collab, index) in lastestCollaborations"
                    :key="index"
                  >
                    <div class="erow">
                      <div class="row">
                        <div class="col-12 col-md-4">
                          <img
                            :src="
                              '/assets/institutions/' +
                              collab.institution.id +
                              '.png'
                            "
                            class="img-fluid"
                          />

                          <!-- <div class="mt-4">
                                            <i class="fas fa-user-graduate"></i> 3 students
                                        </div> -->
                        </div>
                        <div class="col-12 col-md-8">
                          <div class="headline mb-2">
                            {{ collab.title }}
                          </div>
                          <span class="btn btn-link color-third btn-sm"
                            >View collaboration</span
                          >
                        </div>
                      </div>
                    </div>
                  </router-link>

                  <div class="text-center">
                    <router-link
                      to="/collaborations/"
                      class="btn btn-secondary"
                    >
                      Find collaborations
                    </router-link>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>

      <div class="dashboard" v-if="user.role === 'Student'">
        <div class="container-fluid">
          <div class="row mobile_flex_direction">
            <div class="col-12 col-md-6">
              <!-- STUDENT COLLABORATION INVITES -->
              <div
                v-if="
                  user.role === 'Student' &&
                  collaborationInvites &&
                  collaborationInvites.length > 0
                "
              >
                <div class="widget widget-invite">
                  <div class="widget-splash splash-line"></div>
                  <div class="widget-header">
                    <h4>Invitation to thesis group</h4>
                    <!-- <div class="widget-navigation">
                                    <router-link :to="'/collaboration/my'" class="widget-nav"><i class="fas fa-link"></i>My collaborations</router-link>
                                </div> -->
                  </div>
                  <div class="widget-body">
                    <router-link
                      :to="
                        '/collaborations/' +
                        invite.invites.collaborationId +
                        '#members'
                      "
                      v-for="(invite, index) in collaborationInvites"
                      :key="index"
                      class="erow row link d-flex"
                    >
                      <div class="col-1 align-self-center">
                        <i class="fas fa-envelope"></i>
                      </div>
                      <div class="col-4 align-self-center">
                        <h6>From</h6>
                        <p class="headline">{{ invite.invites.sender }}</p>
                      </div>
                      <div class="col-5 align-self-center">
                        <h6>Thesis Topic</h6>
                        <p class="headline">
                          {{ cutText(invite.invites.collaborationTitle, 40) }}
                        </p>
                      </div>
                      <div class="col-2 align-self-center">
                        <button
                          type="button"
                          class="btn btn-sm btn-primary"
                          v-if="
                            invite.invites.targetEmail === user.email &&
                            user.role !== 'Professional'
                          "
                          @click="acceptInvitation($event, invite)"
                          title="Accept invite"
                        >
                          Accept
                        </button>
                        <button
                          type="button"
                          class="btn btn-sm btn-link mr-1"
                          v-if="
                            invite.invites.targetEmail === user.email &&
                            user.role !== 'Professional'
                          "
                          @click="declineInvitation($event, invite)"
                          title="Cancel invitation"
                        >
                          Decline
                        </button>
                      </div>
                    </router-link>
                  </div>
                </div>
              </div>
              <div v-if="user.role === 'Student'" class="">
              <div v-for="event in ongoingEvents" :key="event.event_id" class="event-card mb-4">
                <h3>Are you attending {{event.event_name}}</h3>
                <p>Scan the company QR codes and digitally connect with the companies.</p>
                <div class="text-right">
                  <router-link to="/events" class="btn btn-white">Go to events</router-link>
                </div>
              </div>
              </div>
              <div class="widget">
                <div class="widget-splash splash-line"></div>
                <div class="widget-header">
                  <template
                    v-if="collaborationsByUser && collaborationsByUser.length"
                  >
                    <h4>Thesis Topic</h4>
                    <div class="widget-navigation">
                      <router-link :to="'/collaboration/my'" class="widget-nav"
                        ><i class="fas fa-hands-helping"></i>My
                        collaborations</router-link
                      >
                    </div>
                  </template>
                  <template v-else>
                    <h4>Welcome to Excelerate</h4>
                  </template>
                </div>
                <div class="widget-body widget-suggestion">
                  <template
                    v-if="collaborationsByUser && collaborationsByUser.length"
                  >
                    <router-link
                      :to="'/collaborations/' + collab.id"
                      class="card recent-topic"
                      v-for="(collab, index) in collaborationsByUser"
                      :key="index"
                    >
                      <div class="card-image">
                        <img
                          :src="
                            '/assets/institutions/' +
                            collab.collaboration.institution.id +
                            '.png'
                          "
                          class="card-img-top"
                          :title="collab.collaboration.institution.name"
                        />
                      </div>
                      <div class="card-body">
                        <div class="card-area">
                          <small>{{
                            cutText(collab.collaboration.area, 60)
                          }}</small>
                        </div>
                        <hr />
                        <h2 class="card-title">
                          {{ cutText(collab.collaboration.title, 100) }}
                        </h2>
                        <h6 class="card-subtitle">
                          {{ collab.collaboration.subtitle }}
                        </h6>
                        <p class="card-text">
                          {{
                            cutText(
                              htmlToText(collab.collaboration.content),
                              300
                            )
                          }}
                        </p>
                        <div class="card-bottom">
                          <span>{{ collab.collaboration.degree }}</span
                          ><span class="divider">|</span>
                          <span>{{ collab.collaboration.targetSemester }}</span
                          ><span class="divider">|</span>
                          {{
                            collab.collaboration.members &&
                            collab.collaboration.members.length
                          }}
                          members
                          <!-- <span class="badge" v-if="collab.collaboration.members.includes(user.userId)">yours</span> -->
                          <span class="btn btn-link float-right">View</span>
                        </div>
                      </div>
                    </router-link>
                  </template>

                  <div v-else>
                    <div class="embed-responsive mt-1 mb-4">
                      <iframe
                        src="https://player.vimeo.com/video/600589413?h=da970d434f&color=ff0179&title=0&byline=0&portrait=0"
                        frameborder="0"
                        allow="autoplay; fullscreen; picture-in-picture"
                        allowfullscreen
                      ></iframe>
                    </div>
                    <h2>Next step</h2>
                    <p>
                      Good job on creating your profile! But make sure to also
                      complete it.
                    </p>
                    <p>
                      Now, in order for companies to find your thesis topic and
                      you, you have to create your thesis topic.
                    </p>
                    <p>
                      The sooner you get it out there the higher the chance of
                      landing that thesis collaboration.
                    </p>
                    <p class="tip">
                      Tip: If you are writting as a group, you can invite your
                      team to join the thesis collaboration during the creation
                      process.
                    </p>
                    <div class="text-center">
                      <router-link
                        :to="'/collaboration/new'"
                        class="btn btn-primary btn-block btn-lg"
                        >Create your thesis topic</router-link
                      >
                    </div>
                  </div>
                </div>
              </div>
              <div class="col-12 consult-block">
                <h4 class="consult-block__header pt-2">
                  Speak with an Excelerate Expert to kickstart your journey
                </h4>
                <p class="">
                  Book an Excelerate Expert for an online consultation to take
                  advantage of all the benefits and opportunities on Excelerate.
                  <br />
                  <br />
                  Use this opportunity to get advice on how to get started and
                  stand out from the crowd, how to attract the right companies
                  for the type of project collaboration you seek, and how to use
                  Excelerate to kickstart your career, and much more.
                </p>
                <!-- <button class="btn btn-secondary">Book</button> -->
                <div class="text-center">
                  <button
                    class="btn pink-button-consult px-5"
                    onclick="Calendly.initPopupWidget({url: 'https:\/\/calendly.com/excelerate-onboarding/thesis-supervision'});return false;"
                  >
                    Book now
                  </button>
                </div>
              </div>
            </div>
            <div class="col-12 col-md-6">
              <div class="widget">
                <div class="widget-splash splash-line"></div>
                <div class="widget-header">
                  <h4>Profile</h4>
                  <div class="widget-navigation">
                    <router-link :to="'/user/profile-edit'" class="widget-nav"
                      ><i class="fas fa-user-edit"></i>Edit profile</router-link
                    >
                  </div>
                </div>
                <div class="widget-body profile">
                  <div class="row" v-if="user.role === 'Student'">
                    <div class="col-12">
                      <h4>
                        {{ user.firstName }} {{ user.lastName }}
                        <!-- <span class="badge">student</span> -->
                      </h4>
                      <div class="">
                        {{ user.institution.name }} | {{ user.faculty }} |
                        {{ user.degree }} | {{ user.area }} |
                        {{ user.secondaryArea }} | Graduating
                        {{ user.graduatingSemester }}
                      </div>
                    </div>
                  </div>

                  <div class="row">
                    <div class="student-profile" v-if="isLoaded">
                      <div
                        class="profile-skills"
                        v-if="
                          active_steps >= 8 ||
                          (user.isCompleted &&
                            typeof user.isCompleted != 'undefined')
                        "
                      >
                        <ul>
                          <li
                            class="profile-skill-prof"
                            v-for="(skill, index) in profile.hardSkills"
                            :key="1 + index"
                            v-show="skill != ''"
                          >
                            {{ skill }}
                          </li>
                          <li
                            class="profile-skill-core"
                            v-for="(skill, index) in profile.coreSkills"
                            :key="2 + index"
                            v-show="skill != ''"
                          >
                            {{ skill }}
                          </li>
                          <li
                            class="profile-skill-jobs"
                            v-for="(job, index) in profile.jobs"
                            :key="3 + index"
                            v-show="user.isCompleted"
                          >
                            <template
                              v-if="
                                job.name.indexOf('All') != -1 &&
                                job.name &&
                                job.name.length == 3
                              "
                            >
                              {{ job.catgeory_name }} ({{ job.name }})
                            </template>
                            <template v-else>
                              {{ job.name }}
                            </template>
                          </li>
                        </ul>
                      </div>
                      <div class="profile-content">
                        <div
                          v-if="
                            ((typeof profile.description != 'undefined' &&
                              active_steps >= 6) ||
                              (user.isCompleted &&
                                typeof user.isCompleted != 'undefined')) &&
                            profile.description != ''
                          "
                        >
                          <h6>
                            <b>Description</b>
                          </h6>
                          <p v-html="filterLineBreaks(profile.description)"></p>
                        </div>
                        <div
                          v-if="
                            ((typeof profile.passion != 'undefined' &&
                              active_steps >= 7) ||
                              (user.isCompleted &&
                                typeof user.isCompleted != 'undefined')) &&
                            profile.passion != ''
                          "
                        >
                          <h6>
                            <b>My passion</b>
                          </h6>
                          <p v-html="filterLineBreaks(profile.passion)"></p>
                        </div>
                      </div>
                        <complete-signup/>
                      <!-- don't have time to restucture since this is just part of a patch. Will update the entire part as part of the migration -->
                      <div
                        v-if="
                          false
                        "
                      >
                        <h6>
                          Profile Completion level
                          <b>: {{ progress_status }}</b>
                        </h6>
                        <div class="student-progress offer-steps">
                          <ul>
                            <li
                              v-for="(step, index) in steps"
                              :key="index"
                              :class="{ active: step <= active_steps }"
                              @click="
                                step >= 5 && step < active_steps
                                  ? (active_steps = step)
                                  : ''
                              "
                            >
                              <span class="step"></span>
                            </li>
                          </ul>
                        </div>
                        <template v-if="!openDescriptionEditor">
                          <p style="margin-top: 1rem">
                            Companies can search for the area you want to work
                            in after you are done studying - pretty cool right?
                            But, to be found when companies search for this, you
                            have to complete your profile, otherwise, we do not
                            know and then we can't tell the companies and that
                            would be a shame.
                          </p>
                          <p>
                            It will take you less than 5 minutes to complete
                            your profile.
                          </p>
                        </template>
                        <!-- <div class="student-next">
                                            
                                            <button class="btn btn-outline" @click="nextStep(5)" v-if="active_steps == 5">Next</button>
                                        </div> -->
                        <div class="progess-steps">
                          <div class="progress-step" v-if="active_steps == 5">
                            <div
                              class="ps-action"
                              v-if="!openDescriptionEditor"
                            >
                              <button
                                class="btn btn-outline"
                                @click="openDescriptionEditor = true"
                              >
                                Next
                              </button>
                            </div>
                            <div v-if="openDescriptionEditor">
                              <h6>Description</h6>
                              <textarea
                                v-model="profile.description"
                                v-bind:class="{
                                  'limit-exceeded': isCharactersLimitExceeded,
                                }"
                                rows="20"
                                @keyup="validateCharectors('description')"
                                placeholder="Write a description about yourself"
                              ></textarea>
                              <div class="charector-counter">
                                Characters
                                {{
                                  characters ||
                                  (profile.description &&
                                    profile.description.length)
                                }}/2000
                              </div>
                              <div class="ps-action">
                                <button
                                  class="btn btn-outline"
                                  @click="nextStep(6)"
                                  v-bind="{
                                    disabled: isCharactersLimitExceeded,
                                    disabled: desIsEmpty,
                                  }"
                                >
                                  Add Description
                                </button>
                              </div>
                            </div>
                          </div>

                          <div class="progress-step" v-if="active_steps == 6">
                            <div class="ps-action" v-if="!openPassionEditor">
                              <button
                                class="btn btn-outline"
                                @click="openPassionEditor = true"
                              >
                                Add your passion
                              </button>
                            </div>
                            <div v-if="openPassionEditor">
                              <h6>My Passion</h6>

                              <textarea
                                v-model="profile.passion"
                                v-bind:class="{
                                  'limit-exceeded':
                                    isPassionCharactersLimitExceeded,
                                }"
                                rows="20"
                                @keyup="validateCharectors('passion')"
                                placeholder="Tell us what you are passionate about - what excites you ?"
                              ></textarea>
                              <div class="charector-counter">
                                Characters
                                {{
                                  passionCharacter ||
                                  (profile.passion && profile.passion.length)
                                }}/2000
                              </div>
                              <div class="ps-action">
                                <button
                                  class="btn btn-outline"
                                  @click="nextStep(7)"
                                  v-bind="{
                                    disabled: isPassionCharactersLimitExceeded,
                                    disabled: passionIsEmpty,
                                  }"
                                >
                                  Save & Next
                                </button>
                              </div>
                            </div>
                          </div>

                          <div class="progress-step" v-if="active_steps == 7">
                            <div>
                              <h6>Professional Skills</h6>
                              <p style="padding: 0">
                                Let the companies know what your strong sides
                                are - the stuff you do/have been taught.<br />
                                An example could be; Machine Learning, Software
                                Development, Strategy, Social Media and PPC.
                              </p>

                              <div class="pj-loading" v-show="!hardSkillsData">
                                <i class="fas fa-circle-notch fa-spin"></i>
                              </div>
                              <div v-show="hardSkillsData">
                                <div class="input-group">
                                  <label>Skill #1</label>
                                  <autocomplete
                                    :key="11"
                                    input-info='{"type":"hardSkills","skill":"skill_one"}'
                                    @blur="addCoreSkill"
                                    v-bind="{
                                      'default-value':
                                        profile.hardSkills.skill_one,
                                    }"
                                    :search="hardSkillsSource"
                                    placeholder="Eg: Machine Learning"
                                    aria-label="Skill One"
                                    auto-select
                                    :get-result-value="getResultValue"
                                    class="hard-skills"
                                  ></autocomplete>
                                </div>
                                <div class="input-group">
                                  <label>Skill #2</label>
                                  <autocomplete
                                    :key="22"
                                    input-info='{"type":"hardSkills","skill":"skill_two"}'
                                    @blur="addCoreSkill"
                                    v-bind="{
                                      'default-value':
                                        profile.hardSkills.skill_two,
                                    }"
                                    :search="hardSkillsSource"
                                    placeholder="Eg: Software Development"
                                    aria-label="Skill Two"
                                    auto-select
                                    :get-result-value="getResultValue"
                                    class="hard-skills"
                                    @submit="handleSkillTwo"
                                  ></autocomplete>
                                </div>
                                <div class="input-group">
                                  <label>Skill #3</label>
                                  <autocomplete
                                    :key="33"
                                    input-info='{"type":"hardSkills","skill":"skill_three"}'
                                    @blur="addCoreSkill"
                                    v-bind="{
                                      'default-value':
                                        profile.hardSkills.skill_three,
                                    }"
                                    :search="hardSkillsSource"
                                    placeholder="Eg: Strategy"
                                    aria-label="Skill Three"
                                    auto-select
                                    :get-result-value="getResultValue"
                                    class="hard-skills"
                                    @submit="handleSkillThree"
                                  ></autocomplete>
                                </div>
                                <div class="input-group">
                                  <label>Skill #4</label>
                                  <autocomplete
                                    :key="44"
                                    input-info='{"type":"hardSkills","skill":"skill_four"}'
                                    @blur="addCoreSkill"
                                    v-bind="{
                                      'default-value':
                                        profile.hardSkills.skill_four,
                                    }"
                                    :search="hardSkillsSource"
                                    placeholder="Eg: Social Media"
                                    aria-label="Skill Four"
                                    auto-select
                                    :get-result-value="getResultValue"
                                    class="hard-skills"
                                    @submit="handleSkillFour"
                                  ></autocomplete>
                                </div>
                                <div class="input-group">
                                  <label>Skill #5</label>
                                  <autocomplete
                                    :key="55"
                                    input-info='{"type":"hardSkills","skill":"skill_five"}'
                                    @blur="addCoreSkill"
                                    v-bind="{
                                      'default-value':
                                        profile.hardSkills.skill_five,
                                    }"
                                    :search="hardSkillsSource"
                                    placeholder="Eg: PPC"
                                    aria-label="Skill Five"
                                    auto-select
                                    :get-result-value="getResultValue"
                                    class="hard-skills"
                                    @submit="handleSkillFive"
                                  ></autocomplete>
                                </div>

                                <div class="ps-action">
                                  <button
                                    class="btn btn-outline"
                                    @click="nextStep(8)"
                                    v-bind:class="{
                                      disabled:
                                        isPassionCharactersLimitExceeded,
                                    }"
                                    v-bind="{
                                      disabled:
                                        isPassionCharactersLimitExceeded,
                                    }"
                                  >
                                    Save & Next
                                  </button>
                                </div>
                              </div>
                            </div>
                          </div>

                          <div class="progress-step" v-if="active_steps == 8">
                            <div>
                              <h6>Core Skills</h6>
                              <p style="padding: 0">
                                Let the companies know what your strong sides in
                                soft skills are. Examples could be; Critical
                                thinking, problem solving, public speaking,
                                professional writing, teamworking, digital
                                literacy, leadership, professional attitude,
                                work ethic, career management and intercultural
                                fluency etc.
                              </p>

                              <div class="pj-loading" v-show="!coreSkillsData">
                                <i class="fas fa-circle-notch fa-spin"></i>
                              </div>
                              <div v-show="coreSkillsData">
                                <div class="input-group">
                                  <label>Skill #1</label>
                                  <autocomplete
                                    :key="66"
                                    :search="coreSkillsSource"
                                    placeholder="Eg: Critical Thinking"
                                    aria-label="Core Skill One"
                                    input-info='{"type":"coreSkills","skill":"skill_one"}'
                                    v-bind="{
                                      'default-value':
                                        profile.coreSkills.skill_one,
                                    }"
                                    :get-result-value="getResultValue"
                                    @submit="handleCoreSkillOne"
                                    @blur="addCoreSkill"
                                    class="core-skills"
                                  ></autocomplete>
                                </div>
                                <div class="input-group">
                                  <label>Skill #2</label>
                                  <autocomplete
                                    :key="77"
                                    input-info='{"type":"coreSkills","skill":"skill_two"}'
                                    @blur="addCoreSkill"
                                    class="core-skills"
                                    v-bind="{
                                      'default-value':
                                        profile.coreSkills.skill_two,
                                    }"
                                    :search="coreSkillsSource"
                                    placeholder="Eg: Problem Solving"
                                    aria-label="Core Skill Two"
                                    auto-select
                                    :get-result-value="getResultValue"
                                    @submit="handleCoreSkillTwo"
                                  ></autocomplete>
                                </div>
                                <div class="input-group">
                                  <label>Skill #3</label>
                                  <autocomplete
                                    :key="88"
                                    input-info='{"type":"coreSkills","skill":"skill_three"}'
                                    @blur="addCoreSkill"
                                    class="core-skills"
                                    v-bind="{
                                      'default-value':
                                        profile.coreSkills.skill_three,
                                    }"
                                    :search="coreSkillsSource"
                                    placeholder="Eg: Public Speaking"
                                    aria-label="Core Skill Three"
                                    auto-select
                                    :get-result-value="getResultValue"
                                    @submit="handleCoreSkillThree"
                                  ></autocomplete>
                                </div>
                                <div class="input-group">
                                  <label>Skill #4</label>
                                  <autocomplete
                                    :key="99"
                                    input-info='{"type":"coreSkills","skill":"skill_four"}'
                                    @blur="addCoreSkill"
                                    class="core-skills"
                                    v-bind="{
                                      'default-value':
                                        profile.coreSkills.skill_four,
                                    }"
                                    :search="coreSkillsSource"
                                    placeholder="Eg: Team Working"
                                    aria-label="Core Skill Four"
                                    auto-select
                                    :get-result-value="getResultValue"
                                    @submit="handleCoreSkillFour"
                                  ></autocomplete>
                                </div>
                                <div class="input-group">
                                  <label>Skill #5</label>
                                  <autocomplete
                                    :key="101"
                                    input-info='{"type":"coreSkills","skill":"skill_five"}'
                                    @blur="addCoreSkill"
                                    class="core-skills"
                                    v-bind="{
                                      'default-value':
                                        profile.coreSkills.skill_five,
                                    }"
                                    :search="coreSkillsSource"
                                    placeholder="Eg: Leadership"
                                    aria-label="Core Skill Five"
                                    auto-select
                                    :get-result-value="getResultValue"
                                    @submit="handleCoreSkillFive"
                                  ></autocomplete>
                                </div>

                                <div class="ps-action">
                                  <button
                                    class="btn btn-outline"
                                    @click="nextStep(9)"
                                    v-bind:class="{
                                      disabled:
                                        isPassionCharactersLimitExceeded,
                                    }"
                                    v-bind="{
                                      disabled:
                                        isPassionCharactersLimitExceeded,
                                    }"
                                  >
                                    Save & Next
                                  </button>
                                </div>
                              </div>
                            </div>
                          </div>

                          <div class="progress-step" v-if="active_steps == 9">
                            <div
                              class="ps-action"
                              v-if="!openDesiredJobSelector"
                            >
                              <button
                                class="btn btn-outline"
                                @click="openDesiredJobSelector = true"
                              >
                                Add your desired job positions
                              </button>
                            </div>
                            <div v-if="openDesiredJobSelector">
                              <h6>
                                Desired job positions :
                                <span class="jobs-counter"
                                  >{{ profile.jobs && profile.jobs.length }} out
                                  of 10 selected</span
                                >
                              </h6>
                              <p style="padding: 0">
                                Let the companies know what type of job
                                positions you potentially would be open to
                              </p>
                              <div
                                class="pj-selected"
                                v-if="profile.jobs && profile.jobs.length != 0"
                              >
                                <span>Position Selected: </span>
                                <ul>
                                  <li v-for="(sj, i) in profile.jobs" :key="i">
                                    <span
                                      v-if="
                                        sj.name.indexOf('All') != -1 &&
                                        sj.name &&
                                        sj.name.length == 3
                                      "
                                      ><i
                                        class="fas fa-times"
                                        @click="removeSelectedJob(sj)"
                                      ></i>
                                      {{ sj.catgeory_name }} ({{
                                        sj.name
                                      }})</span
                                    >
                                    <span v-else
                                      ><i
                                        class="fas fa-times"
                                        @click="removeSelectedJob(sj)"
                                      ></i>
                                      {{ sj.name }}</span
                                    >
                                  </li>
                                </ul>
                              </div>
                              <div class="profoile-jobs">
                                <div class="pj-loading" v-show="!jobsData">
                                  <i class="fas fa-circle-notch fa-spin"></i>
                                </div>
                                <div v-if="jobsData">
                                  <ul class="job-ctg">
                                    <li
                                      v-for="(jobs, index) in jobsData"
                                      :key="index"
                                    >
                                      <a @click="toggleJobs"
                                        >{{ jobs.name }}
                                        <span>{{ jobs.description }}</span>
                                      </a>

                                      <JobLists
                                        v-if="jobs.subjobs"
                                        :selectedJobs="profile.jobs"
                                        :jobsData="jobs.subjobs"
                                        :category="jobs.id"
                                        @jobSelection="handleJobChange"
                                      ></JobLists>
                                    </li>
                                  </ul>
                                  <div class="ps-action">
                                    <button
                                      class="btn btn-outline"
                                      @click="nextStep(7)"
                                      v-bind="{ disabled: jobIsEmpty }"
                                    >
                                      Save & Next
                                    </button>
                                  </div>
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </template>
  </div>
</template>

<script>
// import moment from 'moment'
import { mapState } from 'vuex';
import { db } from '../../firebase';
import MasterHeader from '@/components/MasterHeader.vue';
import JobLists from '@/components/JobLists.vue';
import Loader from '@/components/Loader.vue';
import Autocomplete from '@trevoreyre/autocomplete-vue';
import '@trevoreyre/autocomplete-vue/dist/style.css';
import { storage } from '../../firebase';
import CompleteSignup from '../../components/student/CompleteSignup.vue';
import SignUpModal from '../../components/signUpModal.vue';

export default {
  // metaInfo: {
  //   script: [
  //     { src: 'https://assets.calendly.com/assets/external/widget.js', async: true, defer: true }
  //   ],
  // },
  data: function () {
    return {
      email: '',
      lastestCollaborations: '',
      steps: 10,
      active_steps: 5,
      profile: {},
      openDescriptionEditor: false,
      characters: 0,
      isCharactersLimitExceeded: false,
      openPassionEditor: true,
      passionCharacter: 0,
      isPassionCharactersLimitExceeded: false,
      hardSkillsData: false,
      coreSkillsData: false,
      jobsData: false,
      openDesiredJobSelector: true,
      progress_status: 'Basic',
      isGoingToNextStep: true,
      jobIsEmpty: true,
      newlyCompleted: false,
      activeEvents: [1],
      // .storage("gs://excelerate2020.appspot.com/hardskills.json")

    };
  },
  metaInfo: {
    title: 'Your Dashboard',
    meta: [
      {
        name: 'description',
        content:
          'A digital platform that connects students and companies via thesis collaboration matching.',
      },
      { property: 'og:title', content: 'Your Dashboard' },
      {
        property: 'og:description',
        content:
          'A digital platform that connects students and companies via thesis collaboration matching.',
      },
    ],
  },
  components: {
    MasterHeader,
    Autocomplete,
    JobLists,
    Loader,
    CompleteSignup,
    SignUpModal,
  },
  created() {
    //todo computed
    this.$store.dispatch('getCollaborationByUserId', this.user); //todo - only do this if needed?
    this.$store.dispatch('getCollaborationInvitesByUserId');
    this.$store.dispatch('getCompanyInvitesByUserId');
    this.$store.dispatch('getCompanyOffersById', this.user);
    // this.coreSkillsLinkTwo = JSON.parse(JSON.stringify(storage.refFromURL('gs://excelerate2020.appspot.com/hardskills.json').getDownloadURL()));
    // this.$store.dispatch('getCompanyCollaborationsById', this.user.company);
  },
  mounted() {
    if (!this.event.events) {
      this.$store.dispatch('getEvents')
    }
  },
  firestore() {
    return {
      lastestCollaborations: db
        .collection('collaborations')
        .where('status', '==', 'published')
        .orderBy('createdDate', 'desc')
        .limit(4),
    };
  },
  computed: {
    ...mapState(['user', 'event']),
    hasNewSignupFlow(){
      const vm = this
      const isValid = vm.$store.getters.currentProgress >= 0 ? true : false
      const isStudent = vm.$store.getters.role === 'Student'
        if (!isValid && isStudent) {
          localStorage.setItem('notSignedUp', 1);
        }
        if (isValid) {
          localStorage.removeItem('notSignedUp');
        }
      return true
    },
    ongoingEvents(){
      if (this.event.events) {
        return this.event.events.ongoingevent
      }
      return false
    },
    hardSkills: () => {
      return storage
        .refFromURL('gs://excelerate2020.appspot.com/hardskills.json')
        .getDownloadURL();
    },
    coreSkills: () => {
      return storage
        .refFromURL('gs://excelerate2020.appspot.com/coreskills.json')
        .getDownloadURL();
    },
    jobListLink: () => {
      return storage
        .refFromURL('gs://excelerate2020.appspot.com/Interestfields.json')
        .getDownloadURL();
    },
    isLoaded: function () {
      var vm = this;
      var result = false;
      if (this.user.userId !== null) {
        if(!this.$store.getters.verifyingUser) {
          if (!this.user.verified && this.user.role === 'Student'){
            vm.$router.push({ path: '/user/student-welcome' })
          } else if(!this.user.verified && this.user.role === 'Professional') {
            vm.$router.push({ path: '/user/student-welcome' })
          }
        }
        let profile = JSON.parse(JSON.stringify(vm.$store.state.user));
        if (profile.isCompleted && typeof profile != 'undefined') {
          vm.profile = profile;
        } else {
          vm.profile = {
            ...{
              hardSkills: {},
              coreSkills: {},
              jobs: [],
              description: '',
              passion: '',
            },
            ...profile,
          };
        }
        result = true;
      }
      return result;
    },
    collaborationsByUser: function () {
      // console.log(this.user,this.$store.state.collaboration.usersCollaborations);
      const collection = this.$store.state.collaboration.usersCollaborations;
      var result = [];

      result = collection.filter((item) => {
        return (
          item.collaboration.status === 'published' ||
          item.collaboration.status === 'draft'
        );
      });

      return result;
    },
    collaborationInvites: function () {
      var self = this;
      let invites = this.$store.state.collaboration.collaborationInvites;
      //   console.log(invites)
      invites = invites.filter(
        (invite) => invite.invites.targetEmail == self.user.email
      );
      return invites;
    },
    companyInvites: function () {
      return this.$store.state.company.usersCompanyInvites;
    },
    companyOffers: function () {
      return this.$store.state.company.companyOffers;
    },
    desIsEmpty: function () {
      let res = true;
      if (this.$store.state.user.userId !== null) {
        res = this.profile.description != '' ? false : true;
      }
      return res;
    },
    passionIsEmpty: function () {
      let res = true;
      if (this.$store.state.user.userId !== null) {
        res = this.profile.passion != '' ? false : true;
      }
      return res;
    },
    // companyCollaborations: function() {
    //     return this.$store.state.company.companyCollaborations;
    // }
  },
  methods: {
    filterLineBreaks(text) {
      return text.replace(/\r?\n/g, '<br/>');
    },
    handleJobChange(job) {
      this.profile.jobs = job;
    },
    hardSkillsSource(input) {
      let hsds = JSON.parse(JSON.stringify(this.hardSkillsData));
      // console.log(hsds);
      if (input.length < 1) {
        return [];
      }
      return hsds.filter((hsd) => {
        return hsd.name.toLowerCase().startsWith(input.toLowerCase());
      });
    },
    coreSkillsSource(input) {
      //  console.log(this.coreSkillsData);
      let hsds = JSON.parse(JSON.stringify(this.coreSkillsData));
      // console.log(hsds);
      if (input.length < 1) {
        return [];
      }
      return hsds.filter((hsd) => {
        return hsd.name.toLowerCase().startsWith(input.toLowerCase());
      });
    },
    // We want to display the name
    getResultValue(result) {
      return result.name;
    },
    addCoreSkill(e) {
      // console.log(e,e.target.getAttribute('input-info'))
      let interedVal = JSON.parse(e.target.getAttribute('input-info'));
      this.profile[interedVal.type][interedVal.skill] = e.target.value;
    },
    handleSkillOne(result) {
      this.profile.hardSkills.skill_one = result.name;
    },
    handleSkillTwo(result) {
      this.profile.hardSkills.skill_two = result.name;
    },
    handleSkillThree(result) {
      this.profile.hardSkills.skill_three = result.name;
    },
    handleSkillFour(result) {
      this.profile.hardSkills.skill_four = result.name;
    },
    handleSkillFive(result) {
      this.profile.hardSkills.skill_five = result.name;
    },
    handleCoreSkillOne(result) {
      this.profile.coreSkills.skill_one = result.name;
    },
    handleCoreSkillTwo(result) {
      this.profile.coreSkills.skill_two = result.name;
    },
    handleCoreSkillThree(result) {
      this.profile.coreSkills.skill_three = result.name;
    },
    handleCoreSkillFour(result) {
      this.profile.coreSkills.skill_four = result.name;
    },
    handleCoreSkillFive(result) {
      this.profile.coreSkills.skill_five = result.name;
    },
    async nextStep(step) {
      if (this.active_steps == '7') {
        this.validateHardSkills();
      }
      if (this.active_steps == '8') {
        this.validateCoreSkills();
      }
      if (this.isGoingToNextStep) {
        this.active_steps = this.active_steps + 1;
      }
    },
    async loadHardSkills() {
      let hardSkillsLink = await this.hardSkills;
      fetch(hardSkillsLink, {
        method: 'GET',
      })
        .then((res) => res.json())
        .then((result) => {
          this.hardSkillsData = result[0].jobs.sort(function (a, b) {
            return a.name.localeCompare(b.name);
          });
        });
    },
    async loadCoreSkills() {
      let coreSkillsLink = await this.coreSkills;
      fetch(coreSkillsLink, {
        method: 'GET',
      })
        .then((res) => res.json())
        .then((result) => {
          this.coreSkillsData = result[0].jobs.sort(function (a, b) {
            return a.name.localeCompare(b.name);
          });
        });
    },
    async loadJobs() {
      let jobListLink = await this.jobListLink;
      fetch(jobListLink, {
        method: 'GET',
      })
        .then((res) => res.json())
        .then((result) => {
          let jobs = [];
          result.forEach((parent) => {
            parent.field_one.forEach((stream) => {
              let ctgsSorted = stream.field_two.sort((a, b) =>
                a.name.localeCompare(b.name)
              );
              ctgsSorted.forEach((ctgs) => {
                let subjobs = [];
                ctgs.field_three.forEach((job) => {
                  subjobs.push({
                    __id: job.id,
                    name: job.name,
                    catgeory: ctgs.id,
                    catgeory_name: ctgs.name,
                    stream: stream.id,
                    parent: parent.id,
                  });
                });
                jobs.push({
                  id: ctgs.id,
                  name: ctgs.name,
                  subjobs: subjobs,
                  description: ctgs.description,
                });
              });
            });
          });
          this.jobsData = jobs.sort((a, b) => a.name.localeCompare(b.name));
        });
    },
    selectJobs(e, job) {
      let parent = e.target.parentNode;
      let checkbox = e.target;
      parent.classList.toggle('active');
      this.profile.jobs = this.profile.jobs.filter(
        (selected) => selected != job
      );
      if (checkbox.checked && this.profile.jobs.length <= 9) {
        this.profile.jobs.push(job);
      } else {
        checkbox.checked = false;
      }
      this.profile.jobs.sort(function (a, b) {
        return a.name.localeCompare(b.name);
      });
    },
    removeSelectedJob(job) {
      this.profile.jobs = this.profile.jobs.filter(
        (selected) => selected != job
      );
    },
    isSelected(job) {
      this.profile.jobs.forEach((selected) => {
        return selected == job;
      });
    },
    updateStudentProfile() {
      //for students Only
      if (this.user.role === 'Student') {
        this.profile.isCompleted = true;
        this.$store.dispatch('updateUserDetails', this.profile);
      }
    },
    validateHardSkills() {
      this.isGoingToNextStep = true;
      document.querySelectorAll('.hard-skills').forEach((elem) => {
        if (elem.querySelector('input').value == '') {
          elem.classList.add('required');
        } else {
          elem.classList.remove('required');
        }
      });

      document.querySelectorAll('.hard-skills.required').length > 0
        ? (this.isGoingToNextStep = false)
        : '';
    },
    validateCoreSkills() {
      this.isGoingToNextStep = true;
      document.querySelectorAll('.core-skills').forEach((elem) => {
        if (elem.querySelector('input').value == '') {
          elem.classList.add('required');
        } else {
          elem.classList.remove('required');
        }
      });

      document.querySelectorAll('.core-skills.required').length > 0
        ? (this.isGoingToNextStep = false)
        : '';
    },
    validateCharectors(type) {
      if (type === 'passion') {
        this.passionCharacter = this.profile.passion.length;
        if (this.passionCharacter > 2000) {
          this.isPassionCharactersLimitExceeded = true;
        } else {
          this.isPassionCharactersLimitExceeded = false;
        }
      } else {
        this.characters = this.profile.description.length;
        if (this.characters > 2000) {
          this.isCharactersLimitExceeded = true;
        } else {
          this.isCharactersLimitExceeded = false;
        }
      }
    },
    acceptCompanyInvitation(payload) {
      var vm = this;
      //TODO: MAIL 'Professional - New professional Member Joined' -> senderEmail @param1: user.firstName, param2: user.lastName

      const templateData = {
        toEmail: payload.invites.senderEmail,
        firstName: vm.user.firstName,
        lastName: vm.user.lastName,
        param1: payload.invites.sender,
        param2: payload.invites.companyId,
        template: 'Professional - New professional Member Joined',
      };
      vm.$store.dispatch('sendMail', templateData);

      this.$store.dispatch('acceptCompanyInvitation', payload);
    },
    declineCompanyInvitation(payload) {
      var vm = this;
      //TODO: MAIL 'Professional - Declined invitation'
      //receiver email: senderEmail
      //firstName (this user), lastName (this user), param1: sender

      const templateData = {
        toEmail: payload.invites.senderEmail,
        firstName: vm.user.firstName,
        lastName: vm.user.lastName,
        param1: payload.invites.sender,
        param2: payload.invites.companyId,
        template: 'Professional - Declined invitation',
      };
      vm.$store.dispatch('sendMail', templateData);

      this.$store.dispatch('deleteUsersCompanyInviteById', payload);
    },
    cutText: function (text, characters) {
      if (typeof text !== typeof undefined) {
        return text.length > characters
          ? text.substring(0, characters) + '...'
          : text;
      }
    },
    toggleJobs(e) {
      document.querySelectorAll('ul.job-ctg li').forEach((elem) => {
        elem.classList.remove('active');
      });
      e.target.parentNode.classList.toggle('active');
    },
    declineInvitation(e, invite) {
      e.preventDefault();

      const data = {
        // collabId: this.$route.params.id,
        inviteId: invite.id,
      };
      // if(invite.invites.targetEmail == this.user.email){
      const templateData = {
        toEmail: invite.invites.targetEmail,
        param1: this.user.firstName,
        template: 'Student - Declined invitation',
      };
      this.$store.dispatch('sendMail', templateData);
      // }
      // console.log(invite);
      this.$store.dispatch('deleteCollaborationInvite', data);
      // this.$store.dispatch('getCollaborationInvitesByUserId'); // deleteCollaborationInviteById does this now..
    },
    acceptInvitation(e, invite) {
      e.preventDefault();
      var vm = this;
      var data = {
        collaborationId: invite.invites.collaborationId,
        inviteId: invite.id,
        interests: this.user.interests,
      };
      vm.$store.dispatch('acceptCollaborationInvitationFromHome', data);

      //MAIL 'Student - New Student Member Joined'
      const templateData = {
        toEmail: invite.invites.senderEmail,
        firstName: vm.user.firstName,
        lastName: vm.user.lastName,
        param1: invite.invites.title,
        param2: invite.invites.collaborationId,
        template: 'Student - New Student Member Joined',
      };
      vm.$store.dispatch('sendMail', templateData);
      // vm.$store.dispatch('getCollaborationInvitesByUserId');
      // this.$store.dispatch('getCollaborationInvitesByCollabId', this.$route.params.id);
    },
    htmlToText(html) {
      let div = document.createElement('div');
      div.style.display = 'none';
      div.innerHTML = html;
      return div.innerText;
    },
  },
  watch: {
    'profile.jobs': function (val) {
      this.jobIsEmpty = val.length == 0 ? true : false;
    },
    // characters: function(val) {
    //   this.desIsEmpty = val == 0 ? true : false;
    // },
    // passionCharacter: function(val) {
    //   this.passionIsEmpty = val == 0 ? true : false;
    // },
    active_steps: function (val) {
      //do something when the data changes.

      if (val == '10') {
        this.progress_status = 'Done!';
        this.newlyCompleted = true;
        this.updateStudentProfile();
      }
      if (val == '9') {
        this.loadJobs();
        this.progress_status = 'Nearly Done';
      }
      if (val == '8') {
        this.loadCoreSkills();
        this.progress_status = 'Advanced';
      }
      if (val == '7') {
        this.loadHardSkills();
        this.progress_status = 'Intermediate';
      }
      if (val == '6') {
        this.progress_status = 'Enthusiastic';
      }
      if (val < '6') {
        this.progress_status = 'Basic';
      }
    },
  },
};
</script>

<style lang="scss" scoped>
.dashboard {
  .recent-topic {
    h2,
    h6 {
      color: #111;
    }
  }
  .widget-suggestion {
    font-size: 14px;
    color: #333;
  }
  .temp-widget {
    margin-bottom: 10px;

    .widget-content {
      border-radius: 7px;
      color: #333;
      background: #ffffff;
      border: 1px solid #f1f1f1;
      box-shadow: 3px 3px 6px #f1f1f1;
      padding: 20px;
      font-size: 20px;

      margin-bottom: 10px;
    }
  }
  .widget-header {
    color: #000;
  }
  .profile {
    padding: 1rem;

    h2 {
      color: #333;
    }
    h6 {
      font-weight: 400;
      color: #333;
      margin: 20px 1rem 0;
      font-size: 14px;
      b {
        font-weight: bold;
      }
    }
    p {
      color: #333;
      padding: 0 1rem;
      font-size: 14px;
    }
    .tip {
      font-style: italic;
    }
    .student-profile {
      width: 100%;
    }
    .offer-steps {
      padding: 0 1rem;
      margin-top: 8px;
      margin-bottom: 20px;
      ul {
        width: 100%;
      }
      .step {
        display: block;
        border: none;
        background: #d5d5d5;
        height: 9px;
      }
      .active .step {
        background: #d22d89;
      }

      ul li {
        width: 9.28%;
        margin-right: 0.2rem;
      }
    }
    .student-next {
      text-align: center;
    }
    .btn-outline {
      border: 1px solid #d22d89;
      color: #d22d89;
      padding: 0.5rem 4rem;
      font-size: 16px;
      font-weight: 600;
    }
    .btn-outline:hover {
      background: #d22d89;
      color: #fff;
    }
    .progess-steps {
      padding: 0 1rem;
      .ps-action {
        text-align: center;
        margin-top: 1rem;
        clear: both;
      }
      h6 {
        margin: 0;
        padding: 1rem 0;
        font-weight: 600;
        span.jobs-counter {
          color: #d22d89;
          font-weight: 400;
        }
      }
      textarea {
        width: 100%;
        display: block;
        padding: 1rem;
        outline: none;
        border: 1px solid #ddd;
        border-radius: 4px;
        background: #fcfcfc;
      }
      .charector-counter {
        text-align: right;
        font-size: 12px;
        margin-top: 2px;
      }
      textarea:focus-visible {
        border: 1px solid #ccc;
      }
      textarea.limit-exceeded {
        border-color: red;
      }
      .input-group label {
        color: #333;
        font-size: 12px;
      }
    }

    .profile-content h6 {
      margin-bottom: 0.4rem;
    }
    .input-group div {
      width: 100%;
    }
    .profile-skills ul li {
      float: left;
      list-style: none;
      border: 1px solid #da518a;
      padding: 0.2rem 0.5rem;
      border-radius: 8px;
      margin-right: 0.4rem;
      margin-bottom: 0.4rem;
      color: #da518a;
      font-size: 12px;
    }
    .profile-skills ul li:last-child {
      margin-bottom: 1rem;
    }

    .profile-skills ul {
      padding: 1rem 1rem 3rem;
      display: block;
    }

    .profile-content {
      clear: both;
    }

    .profile-skills ul li.profile-skill-core {
      border: 1px solid #4c2f71;
      color: #4c2f71;
    }
    .profile-skills ul li.profile-skill-jobs {
      border: 1px solid #4c9674;
      color: #4c9674;
    }
    .profoile-jobs {
      display: block;
      margin-bottom: 1rem;
    }
    .profoile-jobs ul.job-ctg {
      list-style: none;
      padding: 0;
      display: flex;
      flex-wrap: wrap;
    }
    .profoile-jobs ul.job-ctg li {
      float: left;
      margin-bottom: 0.4rem;
    }
    .profoile-jobs ul.job-ctg li a {
      border: 1px solid #d22d89;
      padding: 0.4rem 1.2rem;
      display: inline-block;
      color: #333;
      margin-right: 1rem;
      border-radius: 14px;
      cursor: pointer;
      position: relative;
      font-weight: 700;
    }
    ul.job-ctg li a span {
      display: block;
      font-size: 10px;
      color: #333;
      pointer-events: none;
      font-weight: 500;
    }

    ul.job-ctg li:hover a,
    ul.job-ctg li.active a {
      color: #d22d89;
    }
    ul.job-ctg li.active a {
      padding: 0.4rem 2rem;
      margin-bottom: 1rem;
    }

    .dashboard .profile .profoile-jobs ul.job-ctg li.active {
      float: none;
      width: 100%;
    }
    ul.job-lists li label {
      color: #333;
      margin-bottom: 0;
    }
    .pj-loading {
      width: 100%;
      height: 201px;
      background: rgb(235 235 235);
      top: 0;
      left: 0;
      position: relative;
      border-radius: 8px;
    }

    .pj-loading i {
      position: absolute;
      top: 46%;
      left: 46%;
      font-size: 36px !important;
      color: #d22d89;
      transform: translate(-50%, -50%);
    }
  }

  .pj-selected {
    display: flex;
    flex-wrap: wrap;
    margin-bottom: 1rem;
  }

  .pj-selected span {
    color: #333;
    font-weight: 500;
    margin-right: 1rem;
  }

  .pj-selected ul {
    list-style: none;
    padding: 0;
  }

  .pj-selected ul li {
    float: left;
    margin-right: 1rem;
    color: #d22d89;
  }

  .pj-selected ul li i {
    display: inline-block;
    width: 16px;
    height: 16px;
    background: #d22d89;
    border-radius: 50%;
    color: #fff;
    font-size: 10px !important;
    padding: 0 5px;
    line-height: 1.5;
    cursor: pointer;
  }
}
.widget-invite h6 {
  color: #000;
  font-weight: 600;
  font-size: 18px;
  margin: 0;
}

.widget.widget-invite p {
  margin: 0;
  color: #000;
}
</style>
<style>
.input-group input {
  padding: 8px 8px 8px 44px;
  font-size: 14px;
  margin-bottom: 1rem;
  background-size: 16px;
  border-radius: 4px;
  background-color: transparent;
}
.autocomplete-result {
  padding: 8px 8px 8px 44px;
  background-size: 16px;
}

.autocomplete-result-list {
  margin-top: -1rem !important;
}
.active a span:after {
  content: '';
  width: 0;
  height: 0;
  border-right: 1.6rem solid transparent;
  border-left: 1.6rem solid transparent;
  /* border-top: 1.5rem solid #CCCCCC; */
  position: absolute;
  bottom: -10px;
  left: 50%;
  -webkit-transform: translate(-50%, -50%);
  transform: translate(-50%, -50%);
  border-top: 0.4rem solid #d22d89;
}

.hard-skills.required input,
.core-skills.required input {
  border: 1px solid red;
}
.erow .headline {
  font-weight: 400;
  color: #361d49;
  font-size: 16px;
  color: #333;
}
.embed-responsive {
  height: 325px;
}
.embed-responsive iframe {
  width: 100%;
  /* height: 261px; */
}
.consult-block {
  width: 100%;
  /* height: 314px; */
  background: transparent linear-gradient(90deg, #5a2871 0%, #bc1e73 100%) 0% 0%
    no-repeat padding-box;
  box-shadow: 0px 3px 10px #0000000d;
  border-radius: 5px;
  opacity: 1;
  color: #fff;
}
.consult-block p {
  font-size: 14px;
}
.consult-block__header {
  font: var(--unnamed-font-style-normal) normal var(--unnamed-font-weight-bold)
    32px/48px var(--unnamed-font-family-poppins);
  letter-spacing: var(--unnamed-character-spacing-0);
  text-align: left;
  font: normal normal bold 32px/48px Poppins;
  color: white;
}
.consult-block__span {
  color: #bc1e73;
}
.pink-button-consult {
  color: #bc1e73;
  background: white;
  width: 200px;
  margin-top: 20px;
  margin-bottom: 20px;
}
</style>
<style>
@media (max-width: 599px) {
  .mobile_flex_direction {
    flex-direction: column-reverse !important;
  }
}
</style>
